import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import "../Profiles/profileimg.css";
import Card6 from "../Images/Profiles/Juliet.jpg";

function JulietKyayesimira() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Dr. Juliet Kyayesimira</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item
                className="text1"
                href="/the-team/top-management"
              >
                Top Management
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Dr. Juliet Kyayesimira
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="section border-bottom">
        <div className="container border-top">
          <div className="row">
            <div className="col-md-12 mt-2">
              <h style={{ color: "#008250", fontSize: "16px" }}>
                <b>Dr. Juliet Kyayesimira</b>
              </h>
              <br></br>
              <h>
                <b>Secretary General</b>
              </h>
              <br></br>
              <h className="texty">Natural Resources Conservation Specialist</h>
            </div>
            <div className="col-md-3 mt-2">
              <div className="imagecarded">
                <img
                  src={Card6}
                  alt=""
                  height="auto" width="100%"
                  style={{
                    borderRadius: "45%",
                    boxShadow: "none",
                    transition: "box-shadow 0.3s ease-in-out",
                    transform: "scale(1) 0.5s ease-in-out",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onMouseEnter={(event) => {
                    event.target.style.boxShadow =
                      "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                    event.target.style.transform = "scale(1.01)";
                  }}
                  onMouseLeave={(event) => {
                    event.target.style.boxShadow = "none";
                    event.target.style.transform = "scale(1)";
                  }}
                />
              </div>
            </div>
            <div className="col-md-9 mt-2">
              <p className="texty">
                Juliet holds the position of Secretary General at the Center for
                Integrated Research and Community Development Uganda (CIRCODU),
                where she serves as a Director and Researcher.
              </p>
              <p className="texty">
                Her academic background includes a Doctor of Philosophy (PhD in
                Biology), focusing on post-harvest losses and microbial
                conditions of beef within Uganda's meat value chain.
                Complementing this, she possesses a Master of Science in Biology
                (with a specialization in Natural Resources, Ecology &
                Conservation) and a Bachelor's degree in Science with Education
                from Mbarara University of Science and Technology.
              </p>
              <p className="texty">
                Dr. Juliet's professional engagement encompasses various
                activities such as teaching, overseeing graduate and
                undergraduate research, and contributing to curriculum design.
                Her research interests span across natural resource
                conservation, climate change, energy, botany, and food safety.
              </p>
              <p className="texty">
                With over 16 years of dedicated service at CIRCODU, Juliet
                brings a wealth of expertise in developmental activities,
                implementation, and oversight. Her contributions have been
                notably impactful, encompassing extensive community development
                and outreach initiatives.
              </p>
              <p className="texty">
                She possesses substantial expertise in tool development,
                executing interviews and surveys, facilitating Focus Group
                Discussions, conducting Kitchen Performance Tests, as well as
                carrying out laboratory and field evaluations related to clean
                cookstoves and fuels.
              </p>
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <div className="container textcenter2">
              <div className="col-md-1">
                <a
                  href="https://www.linkedin.com/in/dr-juliet-kyayesimira-37488587/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <LinkedInIcon
                      sx={{ fontSize: "22px", color: "darkblue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://twitter.com/Jkyayesimira"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <TwitterIcon sx={{ fontSize: "22px", color: "blue" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://www.facebook.com/juliet.kyayesimira/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <FacebookOutlinedIcon
                      sx={{ fontSize: "22px", color: "blue" }}
                    />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="https://wa.me/256702557243"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <WhatsAppIcon sx={{ fontSize: "22px", color: "teal" }} />
                  </IconButton>
                </a>
              </div>
              <div className="col-md-1">
                <a
                  href="mailto:kyayesimira@circodu.org.ug"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton sx={{ bgcolor: "#d7ebf3", color: "red" }}>
                    <EmailIcon sx={{ fontSize: "22px", color: "red" }} />
                  </IconButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default JulietKyayesimira;
