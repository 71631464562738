import React from "react";
import Card2 from "../Images/Headers/team.jpg";
import "../Pages/OurHistory.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Card from "react-bootstrap/Card";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
//import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from '@mui/icons-material/X';
import { IconButton } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import ListGroup from "react-bootstrap/ListGroup";
import Card1 from "../Images/Profiles/Alex.jpg";
import Card3 from "../Images/Profiles/Fred.jpg";
import Card4 from "../Images/Profiles/Godfrey.jpg";
import Card5 from "../Images/Profiles/Joseph.jpg";
import Card6 from "../Images/Profiles/Juliet.jpg";
import Card7 from "../Images/Profiles/Denis.jpg";
import Card8 from "../Images/Profiles/Kenneth.jpeg";

function BoardofDirectors() {
  return (
    <div>
      <div className="head-text">
        <div className="head-image">
          <img src={Card2} alt="" height="200" width="100%" />
        </div>
        <div class="text-on-image">
          <h4 className="bold">Top Management</h4>
          <div class="text-center">
            <Breadcrumb>
              <Breadcrumb.Item className="text1" href="/">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="text1">The Team</Breadcrumb.Item>
              <Breadcrumb.Item className="text1">
                Top Management
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <section className="py-4 border-bottom">
        <div className="container border-top">
          <div className="container-flex">
            <div className="row">
              <div className="col-md-12">
                <h4 className="main-heading pt-3">
                  Director General's Message
                </h4>
                <div className="underline-card4"></div>
                <p className="textBOD">
                  It is my pleasure to welcome you to our website on behalf of
                  the entire CIRCODU Family. We appreciate your interest in our
                  organization and are excited to share information about our
                  mission, values, and activities.
                </p>
                <p className="textBOD">
                  At CIRCODU, we strive to provide exceptional services and
                  solutions that are tailored to meet the unique needs of our
                  clients. We are committed to excellence and continuously seek
                  to improve our services through innovation, collaboration, and
                  feedback from our customers and partners.
                </p>
                <p className="textBOD">
                  On this platform, we provide you with easy access to
                  information about our products, services, and areas of
                  expertise. We believe in transparency and open communication,
                  and reflect those values in all aspects of our work. As you
                  explore, you will learn about our team, our approach to
                  problem-solving, and the solutions we provide. We hope that
                  the information you find here will be helpful and informative,
                  and that it will give you a better understanding of what we do
                  and how we can help you achieve your goals.
                </p>
                <p className="textBOD">
                  We would like to thank you for taking the time to visit our
                  website and for considering our organization for your needs.
                  Should you have any questions or need further assistance,
                  please do not hesitate to contact us. We look forward to
                  hearing from you and to the opportunity to work with you.
                </p>
                <div className="mb-2 signoff">
                  <a
                    href="/joseph-ndemere-arineitwe "
                    target="_blank"
                    rel="noopener noreferrer"
                    className="signoff"
                  >
                    Mr. Joseph Ndemere Arineitwe
                  </a>
                  <p className="textsignoff">Director General</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row border-top text-center">
              <div className="container py-2">
                <div className="row text-center">
                  <div className="col-md-3 mt-2">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ width: "18rem" }}>
                        <a
                          href="/kenneth-arinaitwe"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bold2"
                        >
                          <Card.Img
                            variant="top"
                            src={Card8}
                            alt="rounded image with shadow on hover"
                            style={{
                              height: "300px",
                              borderRadius: "50%",
                              boxShadow: "none",
                              transition: "box-shadow 0.3s ease-in-out",
                              transform: "scale(1) 0.5s ease-in-out",
                              width: "95%",
                              position: "relative",
                              overflow: "hidden",
                              marginTop: "8px",
                            }}
                            onMouseEnter={(event) => {
                              event.target.style.boxShadow =
                                "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                              event.target.style.transform = "scale(1.02)";
                            }}
                            onMouseLeave={(event) => {
                              event.target.style.boxShadow = "none";
                              event.target.style.transform = "scale(1)";
                            }}
                          />
                        </a>
                        <Card.Body>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              <a
                                href="/kenneth-arinaitwe"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bold2"
                              >
                                Dr. Kenneth Arinaitwe
                              </a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h6 className="bold4">
                                Chairman, Executive Council
                              </h6>
                            </ListGroup.Item>
                            <ListGroup.Item></ListGroup.Item>
                          </ListGroup>
                          <Card.Text className="bold3">
                            <h>
                              Kenneth is a holder of a Doctor of Philosophy
                              degree conferred by Makerere University,
                              specializing in Environmental Analytical
                              Chemistry. His ...{" "}
                              <a
                                href="/kenneth-arinaitwe"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bold6"
                              >
                                <b>(Read More)</b>
                              </a>
                            </h>
                          </Card.Text>
                        </Card.Body>
                        <div className="container textcenter1">
                          <div className="col-md-2">
                            <a
                              href="https://ug.linkedin.com/in/kennetharinaitwe"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <LinkedInIcon
                                  sx={{ fontSize: "22px", color: "darkblue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2 ">
                            <a
                              href="https://twitter.com/CIRCODU1"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://www.facebook.com/Circodu/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <FacebookOutlinedIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://wa.me/256772858394"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <WhatsAppIcon
                                  sx={{ fontSize: "22px", color: "teal" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="mailto:kenneth.arinaitwe@circodu.org.ug"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <EmailIcon
                                  sx={{ fontSize: "22px", color: "red" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ width: "18rem" }}>
                        <a
                          href="/denis-muhangi"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bold2"
                        >
                          <Card.Img
                            variant="top"
                            src={Card7}
                            alt="rounded image with shadow on hover"
                            style={{
                              height: "300px",
                              borderRadius: "50%",
                              boxShadow: "none",
                              transition: "box-shadow 0.3s ease-in-out",
                              transform: "scale(1) 0.5s ease-in-out",
                              width: "95%",
                              position: "relative",
                              overflow: "hidden",
                              marginTop: "8px",
                            }}
                            onMouseEnter={(event) => {
                              event.target.style.boxShadow =
                                "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                              event.target.style.transform = "scale(1.02)";
                            }}
                            onMouseLeave={(event) => {
                              event.target.style.boxShadow = "none";
                              event.target.style.transform = "scale(1)";
                            }}
                          />
                        </a>
                        <Card.Body>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              <a
                                href="/denis-muhangi"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bold2"
                              >
                                Dr. Denis Muhangi
                              </a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h6 className="bold4">
                                Vice Chairman, Executive Council
                              </h6>
                            </ListGroup.Item>
                            <ListGroup.Item></ListGroup.Item>
                          </ListGroup>
                          <Card.Text className="bold3">
                            <h>
                              Denis earned a Doctor of Philosophy Degree from
                              Makerere University, where his research delved
                              into the ...{" "}
                              <a
                                href="/denis-muhangi"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bold6"
                              >
                                <b>(Read More)</b>
                              </a>
                            </h>
                          </Card.Text>
                        </Card.Body>
                        <div className="container textcenter1">
                          <div className="col-md-2">
                            <a
                              href="https://www.linkedin.com/in/denis-muhangi-69564077/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <LinkedInIcon
                                  sx={{ fontSize: "22px", color: "darkblue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2 ">
                            <a
                              href="https://twitter.com/mdenis_1234"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://www.facebook.com/muhangi.denis.3"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <FacebookOutlinedIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://wa.me/256785284996"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <WhatsAppIcon
                                  sx={{ fontSize: "22px", color: "teal" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="mailto:denis.muhangi@circodu.org.ug"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <EmailIcon
                                  sx={{ fontSize: "22px", color: "red" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ width: "18rem" }}>
                        <a
                          href="/joseph-ndemere-arineitwe "
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bold2"
                        >
                          <Card.Img
                            variant="top"
                            src={Card5}
                            alt="rounded image with shadow on hover"
                            style={{
                              height: "300px",
                              borderRadius: "50%",
                              boxShadow: "none",
                              transition: "box-shadow 0.3s ease-in-out",
                              transform: "scale(1) 0.5s ease-in-out",
                              width: "95%",
                              position: "relative",
                              overflow: "hidden",
                              marginTop: "8px",
                            }}
                            onMouseEnter={(event) => {
                              event.target.style.boxShadow =
                                "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                              event.target.style.transform = "scale(1.02)";
                            }}
                            onMouseLeave={(event) => {
                              event.target.style.boxShadow = "none";
                              event.target.style.transform = "scale(1)";
                            }}
                          />
                        </a>
                        <Card.Body>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              <a
                                href="/joseph-ndemere-arineitwe "
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bold2"
                              >
                                Mr. Joseph Ndemere Arineitwe
                              </a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h6 className="bold4">Director General</h6>
                            </ListGroup.Item>
                            <ListGroup.Item></ListGroup.Item>
                          </ListGroup>
                          <Card.Text className="bold3">
                            <h>
                              Joseph possesses a Master's degree in Mechanical
                              Engineering with a specialization in Sustainable
                              Energy Engineering, ...{" "}
                              <a
                                href="/joseph-ndemere-arineitwe "
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bold6"
                              >
                                <b>(Read More)</b>
                              </a>
                            </h>
                          </Card.Text>
                        </Card.Body>
                        <div className="container textcenter1">
                          <div className="col-md-2">
                            <a
                              href="https://www.linkedin.com/in/joseph-ndemere-arineitwe-56474625/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <LinkedInIcon
                                  sx={{ fontSize: "22px", color: "darkblue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2 ">
                            <a
                              href="https://twitter.com/josephndemere"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://www.facebook.com/josephndemere.arineitwe/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <FacebookOutlinedIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://wa.me/256782433627"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <WhatsAppIcon
                                  sx={{ fontSize: "22px", color: "teal" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="mailto:joseph.ndemere@circodu.org.ug"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <EmailIcon
                                  sx={{ fontSize: "22px", color: "red" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ width: "18rem" }}>
                        <a
                          href="/juliet-kyayesimira"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bold2"
                        >
                          <Card.Img
                            variant="top"
                            src={Card6}
                            alt="rounded image with shadow on hover"
                            style={{
                              height: "300px",
                              borderRadius: "50%",
                              boxShadow: "none",
                              transition: "box-shadow 0.3s ease-in-out",
                              transform: "scale(1) 0.5s ease-in-out",
                              width: "95%",
                              position: "relative",
                              overflow: "hidden",
                              marginTop: "8px",
                            }}
                            onMouseEnter={(event) => {
                              event.target.style.boxShadow =
                                "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                              event.target.style.transform = "scale(1.02)";
                            }}
                            onMouseLeave={(event) => {
                              event.target.style.boxShadow = "none";
                              event.target.style.transform = "scale(1)";
                            }}
                          />
                        </a>
                        <Card.Body>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              <a
                                href="/juliet-kyayesimira"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bold2"
                              >
                                Dr. Juliet Kyayesimira
                              </a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h6 className="bold4">Secretary General</h6>
                            </ListGroup.Item>
                            <ListGroup.Item></ListGroup.Item>
                          </ListGroup>
                          <Card.Text className="bold3">
                            <h>
                              Juliet holds the position of Secretary General at
                              the Center for Integrated Research and Community
                              Development Uganda (CIRCODU), ...{" "}
                              <a
                                href="/juliet-kyayesimira"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bold6"
                              >
                                <b>(Read More)</b>
                              </a>
                            </h>
                          </Card.Text>
                        </Card.Body>
                        <div className="container textcenter1">
                          <div className="col-md-2">
                            <a
                              href="https://www.linkedin.com/in/dr-juliet-kyayesimira-37488587/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <LinkedInIcon
                                  sx={{ fontSize: "22px", color: "darkblue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2 ">
                            <a
                              href="https://twitter.com/Jkyayesimira"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://www.facebook.com/juliet.kyayesimira/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <FacebookOutlinedIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://wa.me/256702557243"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <WhatsAppIcon
                                  sx={{ fontSize: "22px", color: "teal" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="mailto:kyayesimira@circodu.org.ug"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <EmailIcon
                                  sx={{ fontSize: "22px", color: "red" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ width: "18rem" }}>
                        <a
                          href="/godfrey-muhwezi"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bold2"
                        >
                          <Card.Img
                            variant="top"
                            src={Card4}
                            alt="rounded image with shadow on hover"
                            style={{
                              height: "300px",
                              borderRadius: "50%",
                              boxShadow: "none",
                              transition: "box-shadow 0.3s ease-in-out",
                              transform: "scale(1) 0.5s ease-in-out",
                              width: "95%",
                              position: "relative",
                              overflow: "hidden",
                              marginTop: "8px",
                            }}
                            onMouseEnter={(event) => {
                              event.target.style.boxShadow =
                                "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                              event.target.style.transform = "scale(1.02)";
                            }}
                            onMouseLeave={(event) => {
                              event.target.style.boxShadow = "none";
                              event.target.style.transform = "scale(1)";
                            }}
                          />
                        </a>
                        <Card.Body>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              <a
                                href="/godfrey-muhwezi"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bold2"
                              >
                                Mr. Godfrey Muhwezi
                              </a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h6 className="bold4">Director</h6>
                            </ListGroup.Item>
                            <ListGroup.Item></ListGroup.Item>
                          </ListGroup>
                          <Card.Text className="bold3">
                            <h>
                              Godfrey is concurrently pursuing his doctoral
                              studies in Chemistry and serving as a Chemistry
                              Doctoral Candidate at Kyambogo University. His
                              educational ...{" "}
                              <a
                                href="/godfrey-muhwezi"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bold6"
                              >
                                <b>(Read More)</b>
                              </a>
                            </h>
                          </Card.Text>
                        </Card.Body>
                        <div className="container textcenter1">
                          <div className="col-md-2">
                            <a
                              href="https://www.linkedin.com/in/muhwezi-godfrey-a20abb29/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <LinkedInIcon
                                  sx={{ fontSize: "22px", color: "darkblue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2 ">
                            <a
                              href="https://twitter.com/GodfreyMuhwez"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://www.facebook.com/muhwezi.godfrey/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <FacebookOutlinedIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://wa.me/256772384731"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <WhatsAppIcon
                                  sx={{ fontSize: "22px", color: "teal" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="mailto:godfrey.muhwezi@circodu.org.ug"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <EmailIcon
                                  sx={{ fontSize: "22px", color: "red" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ width: "18rem" }}>
                        <a
                          href="/alexander-atuyamba"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bold2"
                        >
                          <Card.Img
                            variant="top"
                            src={Card1}
                            alt="rounded image with shadow on hover"
                            style={{
                              height: "300px",
                              borderRadius: "50%",
                              boxShadow: "none",
                              transition: "box-shadow 0.3s ease-in-out",
                              transform: "scale(1) 0.5s ease-in-out",
                              width: "95%",
                              position: "relative",
                              overflow: "hidden",
                              marginTop: "8px",
                            }}
                            onMouseEnter={(event) => {
                              event.target.style.boxShadow =
                                "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                              event.target.style.transform = "scale(1.02)";
                            }}
                            onMouseLeave={(event) => {
                              event.target.style.boxShadow = "none";
                              event.target.style.transform = "scale(1)";
                            }}
                          />
                        </a>
                        <Card.Body>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              <a
                                href="/alexander-atuyamba"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bold2"
                              >
                                Mr. Alexander Atuyamba
                              </a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h6 className="bold4">Director</h6>
                            </ListGroup.Item>
                            <ListGroup.Item></ListGroup.Item>
                          </ListGroup>
                          <Card.Text className="bold3">
                            <h>
                              Alexander is a qualified professional with a
                              Master of Science degree in Agroecology and a
                              Bachelor of Science degree in Organic Agriculture,
                              both earned from ...{" "}
                              <a
                                href="/alexander-atuyamba"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bold6"
                              >
                                <b>(Read More)</b>
                              </a>
                            </h>
                          </Card.Text>
                        </Card.Body>
                        <div className="container textcenter1">
                          <div className="col-md-2">
                            <a
                              href="https://www.linkedin.com/in/atuyamba-alex-43b18931/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <LinkedInIcon
                                  sx={{ fontSize: "22px", color: "darkblue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2 ">
                            <a
                              href="https://twitter.com/AtuyambaAlex"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://www.facebook.com/atuyamba.alexander/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <FacebookOutlinedIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://wa.me/256783430017"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <WhatsAppIcon
                                  sx={{ fontSize: "22px", color: "teal" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="mailto:alex.atuyamba@circodu.org.ug"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <EmailIcon
                                  sx={{ fontSize: "22px", color: "red" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                  <div className="col-md-3 mt-2">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Card style={{ width: "18rem" }}>
                        <a
                          href="/fred-isabirye"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bold2"
                        >
                          <Card.Img
                            variant="top"
                            src={Card3}
                            alt="rounded image with shadow on hover"
                            style={{
                              height: "300px",
                              borderRadius: "50%",
                              boxShadow: "none",
                              transition: "box-shadow 0.3s ease-in-out",
                              transform: "scale(1) 0.5s ease-in-out",
                              width: "95%",
                              position: "relative",
                              overflow: "hidden",
                              marginTop: "8px",
                            }}
                            onMouseEnter={(event) => {
                              event.target.style.boxShadow =
                                "2px 2px 2px 2px rgba(0, 255, 157, 0.4)";
                              event.target.style.transform = "scale(1.02)";
                            }}
                            onMouseLeave={(event) => {
                              event.target.style.boxShadow = "none";
                              event.target.style.transform = "scale(1)";
                            }}
                          />
                        </a>
                        <Card.Body>
                          <ListGroup className="list-group-flush">
                            <ListGroup.Item>
                              <a
                                href="/fred-isabirye"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bold2"
                              >
                                Mr. Fred Isabirye
                              </a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <h6 className="bold4">Field Manager</h6>
                            </ListGroup.Item>
                            <ListGroup.Item></ListGroup.Item>
                          </ListGroup>
                          <Card.Text className="bold3">
                            <h>
                              Fred holds a Master’s Degree in Intellectual
                              Property (MIP) from Africa University and a
                              Bachelor of Science Degree in Mechanical
                              Engineering (BSc MEC) from ...{" "}
                              <a
                                href="/fred-isabirye"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bold6"
                              >
                                <b>(Read More)</b>
                              </a>
                            </h>
                          </Card.Text>
                        </Card.Body>
                        <div className="container textcenter1">
                          <div className="col-md-2">
                            <a
                              href="https://www.linkedin.com/in/fred-isabirye-b0b59791/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <LinkedInIcon
                                  sx={{ fontSize: "22px", color: "darkblue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2 ">
                            <a
                              href="https://twitter.com/CIRCODU1"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <XIcon
                                  sx={{ fontSize: "22px", color: "black" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://www.facebook.com/Circodu/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <FacebookOutlinedIcon
                                  sx={{ fontSize: "22px", color: "blue" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="https://wa.me/256752876969"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <WhatsAppIcon
                                  sx={{ fontSize: "22px", color: "teal" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                          <div className="col-md-2">
                            <a
                              href="mailto:fred.isabirye@circodu.org.ug"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <IconButton
                                sx={{ bgcolor: "#d7ebf3", color: "red" }}
                              >
                                <EmailIcon
                                  sx={{ fontSize: "22px", color: "red" }}
                                />
                              </IconButton>
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BoardofDirectors;
